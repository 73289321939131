import { ActionFunctionArgs, json, LinksFunction, LoaderFunction, type MetaFunction } from '@remix-run/node';
import { Form, Link, useLoaderData } from '@remix-run/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { ClientOnly } from 'remix-utils/client-only';
import slickTheme from 'slick-carousel/slick/slick-theme.css?url';
import slick from 'slick-carousel/slick/slick.css?url';
import slide_01 from '@assets/images/slide_01.jpg';
import slide_02 from '@assets/images/slide_02.jpg';
import slide_03 from '@assets/images/slide_03.jpg';
import sound_back_02 from '@assets/images/sound_back_02.mp4';
import { WEB_HOST } from '@common/constants';
import ErrorMessageClient from '@components/client/ErrorMessageClient';
import { useCustomSubmit } from '@hooks/useCustomSubmit';
import { postContact } from '@server/home/contact/contact.client.server';
import { getHashTags } from '@server/home/hashtag/hashtag.client.server';
import { HomeHashtagDTO } from '@server/home/hashtag/hashtag.models';
import { getMainVideos } from '@server/home/main-video/main.video.client.server';
import { HomeMainVideoDTO } from '@server/home/main-video/main.video.models';
import { authenticate } from '@server/login/login.server';
import { ApiResponseMessage } from '@server/models/common.models';
import { customToast, notFountRedirectWithToast } from '@server/toast.server';

export const links: LinksFunction = () => [
    { rel: 'stylesheet', href: slick },
    { rel: 'stylesheet', href: slickTheme },
];

export const meta: MetaFunction = () => {
    return [{ title: 'AunionAI' }, { name: 'description', content: '[AunionAI] 메인 페이지' }];
};

export const loader: LoaderFunction = async ({ request }) => {
    const [hashtags, mainVideo] = await Promise.all([getHashTags(request), getMainVideos(request)]);

    return json({ hashtags: hashtags.content! || [], mainVideo: mainVideo.content! || [] });
};

export async function action({ request }: ActionFunctionArgs) {
    await authenticate(request);

    const formData = await request.json();
    const _action = formData._action as string;

    switch (_action) {
        case 'post-contact': {
            const result = await postContact(request, formData);
            return json<ApiResponseMessage<void>>(result, {
                headers: await customToast<void>('update', result),
            });
        }
    }

    await notFountRedirectWithToast('/');
}

export default function Index() {
    const { hashtags, mainVideo } = useLoaderData<{ hashtags: HomeHashtagDTO[]; mainVideo: HomeMainVideoDTO }>();
    const { t, i18n } = useTranslation();
    const settings = {
        className: 'main_slide',
        dots: true,
        autoplay: true,
        draggable: true,
    };

    const { submit } = useCustomSubmit({
        actionKey: 'post-contact',
    });

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitSuccessful },
    } = useForm({
        defaultValues: {
            createdBy: '',
            contactEmail: '',
            contactContent: '',
        },
    });

    const onSubmit = (data) => {
        submit(data);
    };

    return (
        <div className="content">
            <div className="main_con">
                <div className="main_head">
                    <ClientOnly>
                        {() => (
                            <Slider {...settings}>
                                <div className="item">
                                    <div className="slide_img">
                                        <img className="" src={slide_01} alt="" />
                                    </div>
                                    <div className="item_copy_con">
                                        <div className="item_copy_in">
                                            <p className="item_title">Automated Transcribing</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="slide_img">
                                        <img className="" src={slide_02} alt="" />
                                    </div>
                                    <div className="item_copy_con">
                                        <div className="item_copy_in">
                                            <p className="item_title">Automated Dubbing</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="slide_img">
                                        <img className="" src={slide_03} alt="" />
                                    </div>
                                    <div className="item_copy_con">
                                        <div className="item_copy_in">
                                            <p className="item_title">Text-to-Speech</p>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        )}
                    </ClientOnly>
                </div>
            </div>

            <div className="dub_vid">
                {i18n.language === 'en' && mainVideo.videoEn1 && mainVideo.videoEn1.length > 0 && (
                    <video controls>
                        <source
                            src={`${WEB_HOST}${mainVideo.videoEn1[0].fileUrl}`}
                            type={mainVideo.videoEn1[0].fileContentType}
                        />
                    </video>
                )}
                {i18n.language === 'en' && mainVideo.videoEn2 && mainVideo.videoEn2.length > 0 && (
                    <video controls>
                        <source
                            src={`${WEB_HOST}${mainVideo.videoEn2[0].fileUrl}`}
                            type={mainVideo.videoEn2[0].fileContentType}
                        />
                    </video>
                )}
                {i18n.language === 'ko' && mainVideo.videoKo1 && mainVideo.videoKo1.length > 0 && (
                    <video controls>
                        <source
                            src={`${WEB_HOST}${mainVideo.videoKo1[0].fileUrl}`}
                            type={mainVideo.videoKo1[0].fileContentType}
                        />
                    </video>
                )}
                {i18n.language === 'ko' && mainVideo.videoKo2 && mainVideo.videoKo2.length > 0 && (
                    <video controls>
                        <source
                            src={`${WEB_HOST}${mainVideo.videoKo2[0].fileUrl}`}
                            type={mainVideo.videoKo2[0].fileContentType}
                        />
                    </video>
                )}
            </div>

            <div className="point_box">
                <div className="sound_back">
                    <video src={sound_back_02} autoPlay loop muted></video>
                    <div className="sound_cover"></div>
                </div>
            </div>

            <div className="tech_box about_box">
                <div className="com_1280">
                    <p className="title gmt">Technology</p>
                    <p className="copy">{t('main.technology')}</p>
                </div>
            </div>

            <div className="research_box">
                <div className="com_1280">
                    <p className="title gmt">Research Area</p>
                    <div className="resear_list">
                        {i18n.language === 'ko'
                            ? hashtags.map((item) => (
                                  <Link to={item.hashUrl} key={item.id} className="unit_copy">
                                      #{item.hashtagKo}
                                  </Link>
                              ))
                            : hashtags.map((item) => (
                                  <Link to={item.hashUrl} key={item.id} className="unit_copy">
                                      #{item.hashtagEn}
                                  </Link>
                              ))}
                    </div>
                </div>
            </div>

            <div className="about_box">
                <div className="com_1280">
                    <p className="head gmt">About</p>
                    <div className="about_con">
                        <div className="list">
                            <p>{t('main.about1')}</p>
                            <p>{t('main.about2')}</p>
                            <p>{t('main.about3')}</p>
                            {i18n.language === 'ko' && <p>{t('main.about4')}</p>}
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: t('main.about5'),
                                }}
                            ></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main_contact">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="com_1280">
                        <div className="in">
                            <div className="title">
                                <h1 className="">Contact us</h1>
                                <p>Our service creates a better life.</p>
                            </div>
                            <div className="input_con">
                                <div className="input_list">
                                    <div className="unit">
                                        <p>Name / Company Name</p>
                                        <input
                                            placeholder="Please enter your full name/company name."
                                            {...register('createdBy', {
                                                required: 'This field is required.',
                                            })}
                                        />
                                        {errors.createdBy && <ErrorMessageClient message={errors.createdBy.message} />}
                                    </div>
                                    <div className="unit">
                                        <p>E-mail</p>
                                        <input
                                            placeholder="Please enter an e-mail."
                                            {...register('contactEmail', {
                                                required: 'This field is required.',
                                                pattern: {
                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                    message: 'Please enter a valid email address.',
                                                },
                                            })}
                                        />
                                        {errors.contactEmail && (
                                            <ErrorMessageClient message={errors.contactEmail.message} />
                                        )}
                                    </div>
                                    <div className="unit">
                                        <p>Inquiry</p>
                                        <textarea
                                            placeholder="Please enter your inquiry."
                                            {...register('contactContent', {
                                                required: 'This field is required.',
                                            })}
                                        ></textarea>
                                        {errors.contactContent && (
                                            <ErrorMessageClient message={errors.contactContent.message} />
                                        )}
                                    </div>
                                </div>

                                <div className="btn_box">
                                    <button>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
}
